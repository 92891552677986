import reportWebVitals from "./reportWebVitals";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { toggleMessage } from "./utils/events";
import jwt_decode from "jwt-decode";

function checkLocalSession() {
  let response = false;
  let message = "";
  let tokenMarketing = localStorage.getItem("tokenMarketing");
  let tokenVentas = localStorage.getItem("tokenVentas");
  if (tokenMarketing) {
    let result = checkExp(tokenMarketing, "tokenMarketing");
    response = result.response;
    message = result.message;
  }
  if (tokenVentas) {
    let result = checkExp(tokenVentas, "tokenVentas");
    response = result.response;
    message = result.message;
  }

  if (message) toggleMessage("error", message);
  return response;
}

function checkExp(token: string, type: "tokenVentas" | "tokenMarketing") {
  let response = false;
  let message = "";
  let dataBytoken: {
    email: string;
    exp: number;
    family_name: string;
    iat: number;
    nbf: number;
    role: string;
    unique_name: string;
  } = jwt_decode(token);

  try {
    let dateTokenExp = new Date(dataBytoken.exp * 1000);
    let toDay = new Date();
    if (dateTokenExp <= toDay) {
      localStorage.removeItem(type);
    } else {
      response = true;
    }
  } catch (error) {
    message = "Debe iniciar sesión";
  }
  return { response, message };
}
//  function checkLocalSession() {
//    let response = false;
//    let message = "";
//    let tokenMarketing = localStorage.getItem("tokenMarketing");
//    let tokenVentas = localStorage.getItem("tokenVentas");
//    if (tokenMarketing || tokenVentas) {
//      response = true;
//    }
//    if (message) toggleMessage("error", message);
//    return response;
//  }

const PublicRoutes = lazy(() => import("./routes/PublicRoutes"));
const PrivateRoutes = lazy(() => import("./routes/PrivateRoutes"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.Fragment>
    {checkLocalSession() ? <PrivateRoutes /> : <PublicRoutes />}
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
