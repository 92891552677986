import { formatInTimeZone } from "date-fns-tz";

export const eventBus = {
  toastOn(event: keyof typeof EVENTS, callback) {
    document.addEventListener(event, callback);
  },
  toastDispatch(
    event: keyof typeof EVENTS,
    data: CustomEventInit<{
      text: string;
      type: "warning" | "error" | "info" | "success";
    }>
  ) {
    let newEvent = new CustomEvent(event, data);
    document.dispatchEvent(newEvent);
  },

  loadOn(event: keyof typeof EVENTS, callback) {
    document.addEventListener(event, callback);
  },
  loadDispatch(
    event: keyof typeof EVENTS,
    data: CustomEventInit<{ value: boolean }>
  ) {
    let newEvent = new CustomEvent(event, data);
    document.dispatchEvent(newEvent);
  },
};

const EVENTS = {
  TOGGLE_MESSAGE: "TOGGLE_MESSAGE",
  IS_LOADING: "IS_LOADING",
};

export const toggleMessage = (
  type: "warning" | "error" | "info" | "success",
  text
) => {
  eventBus.toastDispatch("TOGGLE_MESSAGE", {
    detail: { text: text, type: type },
  });
};

export const setIsLoading = (value: boolean) => {
  eventBus.loadDispatch("IS_LOADING", { detail: { value: value } });
};

/**
 * Handler para las llamadas a la API en caso de error
 * @param {*} err Error del catch()
 */
export const errorHandling = (err) => {
  let response = "";

  if (err.response) {
    if (err.response.data && err.response.data.errores) {
      response = err.response.data.errores;
    } else {
      response = "Ocurrió un error.";
    }
  } else if (err.request) {
    response = "Ocurrió un error, no se pudo realizar la acción.";
  } else {
    response = "Ocurrió un error, contáctese con Soporte Técnico.";
  }

  return { result: false, message: response };
};

export const DateFormatIgnoreTimeZone = (date: string, format = "ISO8601_DATE_FORMAT") =>
  // This takes and ISO8601 string, and an IANA timezone name.
  // It returns formatted date in target timezone (regardless of the system's local time zone).
  formatInTimeZone(new Date(date), "UTC", FORMAT_TYPE[format.toUpperCase()]);

const DEFAULT_DATE_FORMAT = "dd/MM/yyyy";
const MONTH_YEAR_DATE_FORMAT = "MM/yyyy";
const ISO8601_DATE_FORMAT = "yyyy-MM-dd";

const FORMAT_TYPE = {
  DEFAULT: DEFAULT_DATE_FORMAT,
  MONTH_YEAR: MONTH_YEAR_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
};
